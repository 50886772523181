import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import SelectedFAQ from './SelectedFAQ';
import sectionFAQCTALabel from '../../../__generated__/intl/strings/pages/home/sectionFAQCTALabel';
import sectionFAQSubtitle from '../../../__generated__/intl/strings/pages/home/sectionFAQSubtitle';
import sectionFAQTitle from '../../../__generated__/intl/strings/pages/home/sectionFAQTitle';
import {linkToMarketing} from '../../url/linkToMarketing';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import TextLink from '../design_system/Link/TextLink';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export default function SectionFAQ(): JSX.Element {
  const {spacing} = useAppTheme();
  return (
    <SectionContainer>
      <SectionTitle
        subTitle={sectionFAQSubtitle()}
        title={sectionFAQTitle()}
      />

      <div
        css={css({
          display: 'grid',
          gridTemplateColumns: '2fr 8fr 2fr',
          marginTop: 60,

          [useMediaQuery(MediaQuery.MediumAndLarge)]: {
            gridTemplateColumns: '1fr 10fr 1fr',
          },

          [useMediaQuery(MediaQuery.SmallScreen)]: {
            gridTemplateColumns: '0 12fr 0',
          },
        })}
        data-testid="faq-section"
      >
        <div />
        <Flex
          sx={css({
            flexDirection: 'column',
            boxSizing: 'border-box',
            rowGap: 60,

            [useMediaQuery(MediaQuery.SmallScreen)]: {
              paddingLeft: spacing.x20,
              paddingRight: spacing.x20,
            },
          })}
        >
          <SelectedFAQ />

          <TextLink
            textProps={{
              color: ContentColor.SECONDARY,
            }}
            to={linkToMarketing('/faq')}
          >
            {sectionFAQCTALabel()}
          </TextLink>
        </Flex>
        <div />
      </div>
    </SectionContainer>
  );
}
