import * as Types from '../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import { UpcomingEvent_GroupSessionFragmentDoc } from '../../../lib/components/marketing/__generated__/MarketingFragments.graphql';
import { GetEventStatus_GroupSessionFragmentDoc } from '../../../lib/components/event/__generated__/Event.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpcomingEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UpcomingEventsQuery = { __typename?: 'Query', groupSessions: { __typename?: 'GroupSessionConnection', edges?: Array<{ __typename?: 'GroupSessionEdge', node?: { __typename?: 'GroupSession', id: string, title: string, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string } | null } | null } | null> | null } };


export const UpcomingEventsDocument = gql`
    query UpcomingEvents {
  groupSessions(first: 1, orderBy: {field: START_TIME_UTC, direction: DESC}) {
    edges {
      node {
        id
        ...UpcomingEvent_groupSession
        ...GetEventStatus_groupSession
      }
    }
  }
}
    ${UpcomingEvent_GroupSessionFragmentDoc}
${GetEventStatus_GroupSessionFragmentDoc}`;

/**
 * __useUpcomingEventsQuery__
 *
 * To run a query within a React component, call `useUpcomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingEventsQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
      }
export function useUpcomingEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
        }
export function useUpcomingEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
        }
export type UpcomingEventsQueryHookResult = ReturnType<typeof useUpcomingEventsQuery>;
export type UpcomingEventsLazyQueryHookResult = ReturnType<typeof useUpcomingEventsLazyQuery>;
export type UpcomingEventsSuspenseQueryHookResult = ReturnType<typeof useUpcomingEventsSuspenseQuery>;
export type UpcomingEventsQueryResult = Apollo.QueryResult<UpcomingEventsQuery, UpcomingEventsQueryVariables>;