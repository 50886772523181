import type {FAQQuestion} from './type';

import useAppTheme from '../design_system/hooks/useAppTheme';
import Typography, {Size} from '../design_system/typography/Typography';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: FAQQuestion;
}

export default function FAQCard(props: Props): JSX.Element {
  const {data} = props;
  const {palettes, spacing} = useAppTheme();
  return (
    <div
      css={css({
        border: `2px solid ${palettes.background.neutralSubdued.default}`,
        borderRadius: 6,
        padding: spacing.x32,
      })}
    >
      <Typography
        size={Size.L}
        variant="h1"
        weight={700}
      >
        {data.question}
      </Typography>

      <Typography
        rootStyle={css({
          marginTop: spacing.x20,
        })}
        size={Size.M}
        variant="body1"
      >
        {data.answer}
      </Typography>
    </div>
  );
}
