import intl from '../../../intl';

export default function sectionUpcomingEventsTitle(): string {
  return intl
    .get('pages.home.section-upcoming-events-title')
    .d('Upcoming Events');
}

export function sectionUpcomingEventsPastEventTitle(): string {
  return intl
    .get('pages.home.section-upcoming-events-past-event-title')
    .d('Events');
}
