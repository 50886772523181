export interface QNA {
  readonly answer: string;
  readonly question: string;
}

const FAQ: QNA[] = [
  {
    question: 'コミュニティーにはどのような人がいますか？',
    answer:
      'このコミュニティでは、あなたも含めて、皆がメンバーです。異なるバックグラウンドや知識を持つすべての人々を歓迎します。特にオープンなマインドで他の人との交流に興味を持っている方を歓迎します。',
  },

  {
    question: 'メンターとはなんですか？',
    answer:
      '当コミュニティで、メンターとは、特定の分野での経験を持ち、知識やアドバイスを共有することができる人のことを指します。メンターはカジュアルな会話に積極的に参加していますので、まずは連絡を取ることをお勧めします。',
  },

  {
    question: '実名での登録は必須ですか？ ',
    answer:
      '本コミュニティはキャリア成長を促進するための目的であり、メンバーのやりとりはソーシャルネットワーキング以上と考えています。そのため、快適にやり取りできる環境を築くために、本名での登録を求めています。',
  },

  {
    question: 'コミュニティへの参加に特定の言語要件はありますか？',
    answer:
      '主な言語は日本語です。本プラットフォームは日本向けのキャリア開発と学習コミュニティを作成するために設計されています。ですが英語、中国語、フランス語など、他の言語を話すメンバーもいますので、好きな言語で話せるメンバーを見つけることができます。',
  },

  {
    question: '参加にお金は発生しますか？ ',
    answer:
      'プラットフォームの利用やメンターとの会話は無料です。参加に料金は必要ありません。機密情報を共有したり、他のメンバーに直接支払いを行ったりしないでください。allizが主催するイベント参加も無料です。',
  },
];

export default FAQ;
