import OfferingCard from './OfferingCard';
import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import sectionOfferingCommunityCTALabel from '../../../__generated__/intl/strings/pages/home/sectionOfferingCommunityCTALabel';
import sectionOfferingCommunityDescription from '../../../__generated__/intl/strings/pages/home/sectionOfferingCommunityDescription';
import sectionOfferingCommunityTitle from '../../../__generated__/intl/strings/pages/home/sectionOfferingCommunityTitle';
import sectionOfferingEventCTALabel from '../../../__generated__/intl/strings/pages/home/sectionOfferingEventCTALabel';
import sectionOfferingEventDescription from '../../../__generated__/intl/strings/pages/home/sectionOfferingEventDescription';
import sectionOfferingEventTitle from '../../../__generated__/intl/strings/pages/home/sectionOfferingEventTitle';
import sectionOfferingMentorshipCTALabel from '../../../__generated__/intl/strings/pages/home/sectionOfferingMentorshipCTALabel';
import sectionOfferingMentorshipDescription from '../../../__generated__/intl/strings/pages/home/sectionOfferingMentorshipDescription';
import sectionOfferingMentorshipTitle from '../../../__generated__/intl/strings/pages/home/sectionOfferingMentorshipTitle';
import sectionOfferingSubtitle from '../../../__generated__/intl/strings/pages/home/sectionOfferingSubtitle';
import sectionOfferingTitle from '../../../__generated__/intl/strings/pages/home/sectionOfferingTitle';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import ResponsiveStackColumns from '../layout/ResponsiveStackColumns';

import {css} from '@emotion/react';
import React from 'react';

export default function SectionOffering(): JSX.Element {
  const {palettes} = useAppTheme();

  return (
    <div
      css={css({
        backgroundColor: palettes.background.neutral.disabled,
      })}
      data-testid="offering-section"
    >
      <SectionContainer>
        <SectionTitle
          subTitle={sectionOfferingSubtitle()}
          title={sectionOfferingTitle()}
        />
        <ResponsiveStackColumns
          count={3}
          minWidth="250px"
          stackMediaQuery={useMediaQuery(MediaQuery.SmallAndMedium)}
        >
          <OfferingCard
            ctaLabel={sectionOfferingMentorshipCTALabel()}
            ctaUrl="/community"
            description={sectionOfferingMentorshipDescription()}
            imageUrl="/static/images/offering-mentorship.svg"
            title={sectionOfferingMentorshipTitle()}
          />
          <OfferingCard
            ctaLabel={sectionOfferingEventCTALabel()}
            ctaUrl="/event"
            description={sectionOfferingEventDescription()}
            imageUrl="/static/images/offering-event.svg"
            title={sectionOfferingEventTitle()}
          />
          <OfferingCard
            ctaLabel={sectionOfferingCommunityCTALabel()}
            ctaUrl="/community-rules"
            description={sectionOfferingCommunityDescription()}
            imageUrl="/static/images/offering-community.svg"
            title={sectionOfferingCommunityTitle()}
          />
        </ResponsiveStackColumns>
      </SectionContainer>
    </div>
  );
}
