import * as Types from '../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import { GetEventTitleWithModifier_GroupSessionFragmentDoc, EventCardTimeInfo_GroupSessionFragmentDoc, EventCardLocationInfo_VenueFragmentDoc } from '../../event/__generated__/Event.graphql';
export type UpcomingEvent_GroupSessionFragment = { __typename?: 'GroupSession', id: string, title: string, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string } | null };

export const UpcomingEvent_GroupSessionFragmentDoc = gql`
    fragment UpcomingEvent_groupSession on GroupSession {
  id
  title
  startTimeUTCSeconds
  durationSeconds
  ...GetEventTitleWithModifier_groupSession
  ...EventCardTimeInfo_groupSession
  venue {
    ...EventCardLocationInfo_venue
  }
}
    ${GetEventTitleWithModifier_GroupSessionFragmentDoc}
${EventCardTimeInfo_GroupSessionFragmentDoc}
${EventCardLocationInfo_VenueFragmentDoc}`;