import React from 'react';

export default function SectionAudiencePatternLeft(): JSX.Element {
  return (
    <svg
      fill="none"
      height="549"
      viewBox="0 0 438 549"
      width="438"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.8352 528.105C141.249 558.105 203.369 553.688 254.37 527.846C305.388 502.002 346.599 456.976 381.398 407.936C420.538 352.775 453.931 278.45 427.985 212.553C403.621 150.663 339.228 125.497 285.772 94.2656C227.027 59.9361 172.186 10.0753 106.909 1.4059C33.5934 -8.3215 -39.6738 40.9903 -72.8985 113.976C-106.116 186.951 -101.318 278.735 -67.7592 353.371C-34.2048 427.969 24.3774 488.556 90.8352 528.105Z"
        fill="#FFFAF3"
      />
    </svg>
  );
}
