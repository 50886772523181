import useAppTheme from '../design_system/hooks/useAppTheme';
import TextLink from '../design_system/Link/TextLink';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Image from '../Image/Image';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly ctaLabel: string;
  readonly ctaUrl: string;
  readonly description: string;
  readonly imageUrl: string;
  readonly title: string;
}

export default function OfferingCard(props: Props): JSX.Element {
  const {ctaLabel, ctaUrl, description, imageUrl, title} = props;
  const {spacing} = useAppTheme();
  return (
    <Flex
      sx={css({
        flexDirection: 'column',
        gap: spacing.x12,
      })}
    >
      <Image
        src={imageUrl}
        sx={css({background: 'none'})}
        width="100%"
      />
      <Typography
        size={Size.L}
        weight={700}
      >
        {title}
      </Typography>
      <div
        css={css({
          flex: 1,
        })}
      >
        <Typography size={Size.M}>{description}</Typography>
      </div>
      <TextLink
        textProps={{color: ContentColor.SECONDARY}}
        to={ctaUrl}
      >
        {ctaLabel}
      </TextLink>
    </Flex>
  );
}
