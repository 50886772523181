import FormatText from './FormatText';
import {BaseTextProps, FontFamily} from './options';
import useSharedHTMLTextProps from './useSharedHTMLTextProps';
import useSharedTextCSS from './useSharedTextCSS';

import {css} from '@emotion/react';
import React from 'react';

export interface Props extends BaseTextProps {
  readonly children: React.ReactNode;
}

export default function DetailsText(props: Props): JSX.Element {
  const {children} = props;
  const sharedCSS = useSharedTextCSS(props);
  const htmlProps = useSharedHTMLTextProps(props);
  return (
    <span
      css={[
        sharedCSS,
        css({
          fontFamily: FontFamily.NOTO_SANS_JS,
          fontWeight: 400,
          fontSize: 11,
          lineHeight: '1.4em',
        }),
      ]}
      {...htmlProps}
    >
      <FormatText>{children}</FormatText>
    </span>
  );
}
