import HomePageHero from './components/HomePageHero';
import sectionMemberHighlightSubtitle from '../../__generated__/intl/strings/pages/home/sectionMemberHighglightSubtitle';
import sectionMemberHighlightCTALabel from '../../__generated__/intl/strings/pages/home/sectionMemberHighlightCTALabel';
import sectionMemberHighlightTitle from '../../__generated__/intl/strings/pages/home/sectionMemberHighlightTitle';
import myPageDiscoverPageTitle from '../../__generated__/intl/strings/pages/myPage/discover/myPageDiscoverPageTitle';
import {ButtonColor} from '../../lib/components/design_system/Button/options';
import SignupCTAButton from '../../lib/components/design_system/Button/SignupCTAButton';
import useAppTheme from '../../lib/components/design_system/hooks/useAppTheme';
import DefaultPageLayout from '../../lib/components/layout/DefaultPageLayout';
import SectionAboutAlliz from '../../lib/components/marketing/SectionAboutAlliz';
import SectionAudience from '../../lib/components/marketing/SectionAudience';
import SectionFAQ from '../../lib/components/marketing/SectionFAQ';
import SectionFinalCTA from '../../lib/components/marketing/SectionFinalCTA';
import SectionGetStarted from '../../lib/components/marketing/SectionGetStarted';
import SectionOffering from '../../lib/components/marketing/SectionOffering';
import SectionUpcomingEvent from '../../lib/components/marketing/SectionUpcomingEvent';
import SectionUsers from '../../lib/components/marketing/SectionUsers';
import homeUsers from '../../lib/components/user/raw/homeUsers';

import {css} from '@emotion/react';
import React from 'react';

export default function HomePage(): JSX.Element {
  const {spacing} = useAppTheme();

  return (
    <DefaultPageLayout>
      <HomePageHero />
      <SectionAboutAlliz />
      <SectionOffering />
      <SectionUpcomingEvent />
      <SectionAudience />
      <SectionUsers
        count={4}
        subtitle={sectionMemberHighlightSubtitle()}
        title={sectionMemberHighlightTitle()}
        users={homeUsers}
      >
        <div
          css={css({
            marginTop: spacing.x60,
            textAlign: 'center',
          })}
        >
          <SignupCTAButton
            color={ButtonColor.ACCENT}
            guestLabel={sectionMemberHighlightCTALabel()}
            loggedInLabel={myPageDiscoverPageTitle()}
            next="/mypage/discover"
          />
        </div>
      </SectionUsers>
      <SectionGetStarted />
      <SectionFAQ />
      <SectionFinalCTA />
    </DefaultPageLayout>
  );
}
