import SampleAudienceCard from './SampleAudienceCard';
import SectionAudiencePatternLeft from './SectionAudiencePatternLeft';
import SectionAudiencePatternRight from './SectionAudiencePatternRight';
import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import sectionAudiencePerson1Intro from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson1Intro';
import sectionAudiencePerson1Title from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson1Title';
import sectionAudiencePerson2Intro from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson2Intro';
import sectionAudiencePerson2Subtitle from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson2Subtitle';
import sectionAudiencePerson2Title from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson2Title';
import sectionAudiencePerson3Intro from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson3Intro';
import sectionAudiencePerson3Subtitle from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson3Subtitle';
import sectionAudiencePerson3Title from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson3Title';
import sectionAudiencePerson4Intro from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson4Intro';
import sectionAudiencePerson4Subtitle from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson4Subtitle';
import sectionAudiencePerson4Title from '../../../__generated__/intl/strings/pages/home/sectionAudiencePerson4Title';
import sectionAudienceSubtitle from '../../../__generated__/intl/strings/pages/home/sectionAudienceSubtitle';
import sectionAudienceTitle from '../../../__generated__/intl/strings/pages/home/sectionAudienceTitle';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';

import {css} from '@emotion/react';
import React from 'react';

export default function SectionAudience(): JSX.Element {
  const {spacing} = useAppTheme();
  const stackedMQ = useMediaQuery(MediaQuery.NonExtraLargeScreen);
  const extraLargeSideGap = (
    <div
      css={css({
        [useMediaQuery(MediaQuery.NonExtraLargeScreen)]: {
          display: 'none',
        },
      })}
    />
  );

  const largeGap = (
    <div
      css={css({
        display: 'none',

        [useMediaQuery(MediaQuery.LargeScreen)]: {
          display: 'block',
        },
      })}
    />
  );

  return (
    <div
      css={css({
        position: 'relative',
        overflow: 'hidden',
      })}
      data-testid="audience-section"
    >
      <SectionContainer>
        <div
          css={css({
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: 200,
            userSelect: 'none',
            zIndex: -1,

            [stackedMQ]: {
              top: 'auto',
              bottom: 0,
            },
          })}
        >
          <SectionAudiencePatternLeft />
        </div>

        <div
          css={css({
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            userSelect: 'none',
            zIndex: -1,
          })}
        >
          <SectionAudiencePatternRight />
        </div>

        <SectionTitle
          subTitle={sectionAudienceSubtitle()}
          title={sectionAudienceTitle()}
        />
        <div
          css={css({
            display: 'grid',
            gap: 60,
            gridTemplateColumns: '1fr 5fr 5fr 1fr',
            marginTop: 50,
            padding: spacing.x12,

            [useMediaQuery(MediaQuery.LargeScreen)]: {
              gridTemplateColumns: '1fr 5fr 1fr',
            },

            [useMediaQuery(MediaQuery.SmallAndMedium)]: {
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {extraLargeSideGap}
          {largeGap}
          <SampleAudienceCard
            borderColorName="pink"
            intro={sectionAudiencePerson1Intro()}
            photoURL="/static/images/home-person-1-photo.svg"
            title={sectionAudiencePerson1Title()}
          />
          {largeGap}

          {largeGap}
          <SampleAudienceCard
            borderColorName="blue"
            intro={sectionAudiencePerson2Intro()}
            photoURL="/static/images/home-person-2-photo.svg"
            subtitle={sectionAudiencePerson2Subtitle()}
            title={sectionAudiencePerson2Title()}
          />
          {largeGap}
          {extraLargeSideGap}

          {extraLargeSideGap}
          {largeGap}
          <SampleAudienceCard
            borderColorName="pink"
            intro={sectionAudiencePerson3Intro()}
            photoURL="/static/images/home-person-3-photo.svg"
            subtitle={sectionAudiencePerson3Subtitle()}
            title={sectionAudiencePerson3Title()}
          />
          {largeGap}

          {largeGap}
          <SampleAudienceCard
            borderColorName="blue"
            intro={sectionAudiencePerson4Intro()}
            photoURL="/static/images/home-person-4-photo.svg"
            subtitle={sectionAudiencePerson4Subtitle()}
            title={sectionAudiencePerson4Title()}
          />
          {largeGap}
          {extraLargeSideGap}
        </div>
      </SectionContainer>
    </div>
  );
}
