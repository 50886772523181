import FAQCard from './FAQCard';
import faqData from './faqData';
import useAppTheme from '../design_system/hooks/useAppTheme';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export default function SelectedFAQ(): JSX.Element {
  const {spacing} = useAppTheme();
  const elements = faqData.map((item, i) => {
    return (
      <FAQCard
        data={item}
        key={i}
      />
    );
  });
  return (
    <Flex
      sx={css({
        flexDirection: 'column',
        rowGap: spacing.x24,
      })}
    >
      {elements}
    </Flex>
  );
}
